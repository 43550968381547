import * as React from "react";
import AboutCompany from "../components/aboutCompany";
import AboutInstagram from "../components/aboutInstagram";
import Footer from "../components/footer";
import Header from "../components/header";
import PageHeader from "../components/pageHeader";

const Company = () => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <PageHeader page="company">COMPANY</PageHeader>
        <AboutCompany />
        <AboutInstagram />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Company;

export const Head = () => <title>GoGo Island | COMPANY</title>
