import { Link } from "gatsby";
import * as React from "react";
import * as styles from "../styles/sass/layout/_aboutCompany.module.scss"

const AboutCompany = () => {
  return (
    <>
      <div className="common__layout">
        <h2 className="common__title">会社概要</h2>
      </div>
      <ul className={styles.aboutCompany__list}>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>会社名</h3>
                <p>GoGo Island（ゴーゴーアイランド）</p>
            </div>
        </li>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>所在地</h3>
                <p>愛媛県松山市久万ノ台139-15</p>
            </div>
        </li>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>連絡先</h3>
                <p><Link to="/">InstagramのDM</Link>にて対応させていただきます。（平日10時~17時）</p>
            </div>
        </li>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>代表名</h3>
                <p>山内　加奈江</p>
            </div>
        </li>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>取引銀行</h3>
                <p>伊予銀行</p>
            </div>
        </li>
        <li className={styles.aboutCompany__item}>
            <div className={styles.aboutCompany__box}>
                <h3>設立年月</h3>
                <p>2022年（令和4年5月5日）</p>
            </div>
        </li>
      </ul>
    </>
  );
};

export default AboutCompany;
